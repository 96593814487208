import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { useScrollToSectionOnMount } from "@Utils/hooks/rendering";
import { INavbar } from "../../INavbar";
import styles from "./navbarElementary.module.scss";
import AccountAndNotification from "../../components/AccountsAndNotification";

const NavbarElementary = ({
    notificationProps,
    accountProps,
    navbarItems,
}: INavbar.IChildProps): JSX.Element => {
    // Automatically scroll to the section mentioned in url after "#" after 1200 ms.
    // why 1200 ms?? - to wait for page to load
    useScrollToSectionOnMount(1200);
    const AccountAndNotificationProps = {
        notificationProps: {
            ...notificationProps,
            notificationIconColorScheme: classNames(
                styles.navButtonColorScheme,
                styles.notificationIconColorScheme
            ),
        },
        accountProps: {
            ...accountProps,
            accountIconColorScheme: styles.navButtonColorScheme,
        },
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.mobileMenu}>
                    <a
                        className={classNames(styles.navTitle, "d-flex")}
                        {...navbarItems.home.props}
                    >
                        {navbarItems.home.content}
                    </a>
                </div>
                <div className={styles.flexGrow} />
                {navbarItems.blog.showItem ? (
                    <a
                        key={navbarItems.blog.content.toString()}
                        className={styles.link}
                        {...navbarItems.blog.props}
                    >
                        {navbarItems.blog.content}
                    </a>
                ) : null}
                <AccountAndNotification {...AccountAndNotificationProps} />
                {navbarItems.getInTouch.showItem ? (
                    <div
                        className={styles.navIconButtons}
                        {...navbarItems.getInTouch.props}
                    >
                        <FaPhoneAlt />
                    </div>
                ) : null}
                {navbarItems.login.showItem ? (
                    <div
                        className={styles.navIconButtons}
                        {...navbarItems.login.props}
                    >
                        <IoPerson />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default NavbarElementary;
